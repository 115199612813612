<template>
  <div
    class="minutes-remaining-info-root"
  >
    <span>Model currently uses</span>
    <NumberInput
      :model-value="playerMinutesRemainingValue"
      :readonly="true"
    />
    <span>minutes of remaining play time{{ simpleView ? '.' : ' to produce prices.' }}</span>
  </div>
</template>

<script>
import NumberInput from '@/components/common/NumberInput';

export default {
  props: {
    playerMinutesRemainingValue: {
      type: Number || null,
      default: null,
    },
    simpleView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NumberInput,
  },
};
</script>

<style lang="scss">
.minutes-remaining-info-root {
  align-items: center;
  font-size: 14px;
  .table-number-input {
    display: inline-block;
    margin: 0 4px;
    width: 50px;
    input {
      width: 100%;
      text-align: center;
      background-color: $white;
    }
  }
}
</style>
