<template>
  <ConfirmLineupFieldChangeModal
    :confirm-modal-active="confirmModalActive"
    @onModalClose="onModalClose"
    @onRemovePlayerFromLineup="onLineupChange"
  />
  <div class="player-params-single">
    <div class="player-params-single-header">
      <div class="player-info">
        <div class="position-input-root">
          <div
            v-if="!isPlayerPositionError"
            class="arrows-container"
          >
            <Icon
              :name="'chevron-up'"
              @click="onPositionIncrease"
            />
            <Icon
              :name="'chevron-down'"
              @click="onPositionDecrease"
            />
          </div>
          <NumberInput
            class="position-input"
            :model-value="playerData?.playerPositionIndex"
            :is-error="isPlayerPositionError"
            :error-message="'Position must be a value greater than 0'"
            :readonly="isCalculatedMode"
            @update:modelValue="onPositionChange"
            @onKeyUp="onPositionIncrease"
            @onKeyDown="onPositionDecrease"
          />
        </div>
        <div
          class="player-name"
          :title="playerData?.playerIndex"
        >
          {{ formatPlayerName(playerData?.playerName) }}
          <span
            v-if="handedness"
            class="dot-space"
          >&bull;</span>
          <span v-if="handedness">{{ handedness }}</span>
          <span class="dot-space">&bull;</span>
          <Tooltip
            :text="playerData?.isInTeamLineup ? 'Player is in lineup' : 'Player is not in lineup'"
            right
          >
            <div
              class="player-lineup-indicator"
              :class="{ 'is-in-lineup': playerData?.isInTeamLineup }"
            >
              <Icon
                :name="playerData?.isInTeamLineup ? 'users-check' : 'users-x'"
              />
            </div>
          </Tooltip>
          <span class="dot-space">&bull;</span>
          <Tooltip
            :text="isPlayerMapped ? 'Player is mapped for projections' : 'Player is not mapped for projections'"
            right
          >
            <Icon
              :name="isPlayerMapped ? 'link-not-broken' : 'link-broken'"
              :class="['projections-mapping-icon', { 'is-mapped': isPlayerMapped }]"
            />
          </Tooltip>
        </div>
      </div>
      <div class="player-setup-header-actions">
        <div class="player-disabled-toggle">
          <Toggle
            :model-value="playerData?.disabled"
            :disabled="isCalculatedMode"
            @update:model-value="onIsDisabledChanged"
          />
          <span>Disable</span>
        </div>
        <PlayerParamsSingleHeaderActions
          :player-data="playerData"
          :team-label="teamLabel"
          :active-tab="activeTab"
        />
      </div>
    </div>
    <PlayerMinutesRemainingInfo
      v-if="showMinutesRemainingInfo"
      :player-minutes-remaining-value="playerMinutesRemainingValue"
    />
    <div
      class="player-params-single-container"
      :class="{
        suspended: isSuspendActive,
        disabled: playerData.disabled,
      }"
    >
      <GameParamToggleInput
        :param="lineupParam"
        :disabled="playerData.disabled || isCalculatedMode"
        @paramChange="updatePlayerLineup"
      />
      <div
        v-for="param in activeParams"
        :key="param.key"
        class="player-params-input"
      >
        <GameParamNumberInput
          v-if="typeof param.defaultValue === 'number'"
          :param="param"
          :disabled="playerData.disabled || isCalculatedMode"
          @paramChange="onPlayerParamChange($event, param)"
          @paramIncrease="onPlayerParamIncrease(param)"
          @paramDecrease="onPlayerParamDecrease(param)"
        />
        <GameParamToggleInput
          v-if="typeof param.defaultValue === 'boolean'"
          :param="param"
          :disabled="playerData.disabled || isCalculatedMode"
          @paramChange="onPlayerParamChange"
        />
      </div>
    </div>
    <MarketsPerPlayerSingle
      v-if="showPlayerMarkets"
      class="markets-per-player-container"
      :player-market-info="playerData"
      :team-label="teamLabel"
      :sport-id="sportId"
      :lineup="totalLineup"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import {
  map, includes, filter, cloneDeep,
} from 'lodash';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import PlayerParamsSingleHeaderActions from '@/components/player-setup/common/PlayerParamsSingleHeaderActions';
import GameParamNumberInput from '@/components/player-setup/common/GameParamNumberInput';
import GameParamToggleInput from '@/components/player-setup/common/GameParamToggleInput';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import Toggle from '@/components/common/Toggle';
import NumberInput from '@/components/common/NumberInput';
import MarketsPerPlayerSingle from '@/components/trading-ui/multiview/event-drawer/params/MarketsPerPlayerSingle';
import ConfirmLineupFieldChangeModal from '@/components/player-setup/common/ConfirmLineupFieldChangeModal';
import {
  parseEventModel,
} from '@/services/helpers/event-drawer';
import { formatPlayerName, getPlayerMinutesRemainingValue } from '@/services/helpers/player-setup-mapper';

import sportIds from '@/services/helpers/sports';
import PlayerMinutesRemainingInfo from '@/components/player-setup/common/PlayerMinutesRemainingInfo';

const {
  BASKETBALL_ID,
} = sportIds;

export default {
  emits: ['onParamUpdate', 'onPlayerParamUpdate'],
  props: {
    playerData: {
      type: Object,
      default: () => ({}),
    },
    activeTab: {
      type: Object,
      default: () => ({}),
    },
    teamLabel: {
      type: String,
      default: '',
    },
    isCalculatedMode: {
      type: Boolean,
      default: false,
    },
    handedness: {
      type: String,
      default: '',
    },
    activeParams: {
      type: Array,
      default: () => [],
    },
    sportId: {
      type: String,
      default: '',
    },
  },
  components: {
    PlayerParamsSingleHeaderActions,
    GameParamNumberInput,
    GameParamToggleInput,
    Icon,
    Tooltip,
    Toggle,
    NumberInput,
    MarketsPerPlayerSingle,
    ConfirmLineupFieldChangeModal,
    PlayerMinutesRemainingInfo,
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const isPlayerPositionError = computed(() => props.playerData?.playerPositionIndex <= 0);
    const playerSetupMappedPlayersForProjections = computed(() => map(store.getters.playerSetupMappedPlayersForProjections, (player) => player.playerId));
    const isPlayerMapped = computed(() => includes(playerSetupMappedPlayersForProjections.value, props.playerData.playerId));
    const hasSubmitedMarkets = computed(() => !!filter(props.playerData.marketCodeDetails, (market) => !market.isLocked).length);
    const isSuspendActive = computed(() => hasSubmitedMarkets.value && !filter(props.playerData?.marketCodeDetails, (market) => !market.suspended && !market.isLocked).length);
    const showMinutesRemainingInfo = computed(() => props.sportId === BASKETBALL_ID);
    const pricingProbabilities = computed(() => store.getters.playerSetupData?.probabilities);
    const playerMinutesRemainingValue = computed(() => {
      if (!showMinutesRemainingInfo.value) return 0;
      return getPlayerMinutesRemainingValue({
        probabilities: pricingProbabilities.value,
        playerIndex: props.playerData.playerIndex,
        teamSide: props.playerData.side,
      });
    });

    // Position param
    const updatePositionParam = (newVal) => {
      emit('onParamUpdate', {
        playerId: props.playerData.playerId,
        paramKey: 'playerPositionIndex',
        value: newVal,
      });
    };

    const onPositionIncrease = () => {
      const newValue = parseFloat((props.playerData.playerPositionIndex + 1).toFixed(3));
      updatePositionParam(newValue);
    };

    const onPositionDecrease = () => {
      const newValue = parseFloat((props.playerData.playerPositionIndex - 1).toFixed(3));
      if (newValue <= 0) return;
      updatePositionParam(newValue);
    };

    const onPositionChange = (val) => {
      let newValue = parseFloat(val);
      const isNaN = Number.isNaN(parseFloat(newValue));
      if (isNaN) {
        newValue = val;
      }
      updatePositionParam(newValue);
    };

    // Player disabled
    const onIsDisabledChanged = (newVal) => {
      emit('onParamUpdate', {
        playerId: props.playerData.playerId,
        paramKey: 'disabled',
        value: newVal,
      });
    };

    const showPlayerMarkets = computed(() => route.name === 'trading-ui' || route.name === 'trading-ui-multiview');
    const totalLineup = computed(() => {
      if (route.name === 'trading-ui') {
        const event = parseEventModel(store.getters.tradingEvent);
        return [
          ...(event?.homeLineup || []),
          ...(event?.awayLineup || []),
        ];
      }
      return [];
    });

    // Lineup param
    const lineupParam = computed(() => ({
      key: 'lineup',
      shortName: 'Lineup',
      longName: 'Is player in lineup',
      value: props.playerData?.lineup || false,
      disabled: !props.playerData.lineup && props.playerData.playerLocked,
    }));

    const confirmModalActive = ref(false);

    const onModalClose = () => {
      confirmModalActive.value = false;
    };

    const updatePlayerLineup = (val) => {
      if (!val) {
        confirmModalActive.value = true;
      }
    };

    const onLineupChange = (newVal) => {
      emit('onParamUpdate', {
        playerId: props.playerData.playerId,
        paramKey: 'lineup',
        value: newVal,
      });
      onModalClose();
    };

    // Player params
    const updateParam = (newVal, param) => {
      emit('onPlayerParamUpdate', { newVal, param });
    };

    const onPlayerParamChange = (value, param) => {
      let newValue = value;
      const isNumberVal = typeof param.defaultValue === 'number';
      if (isNumberVal) {
        let paramValue = parseFloat(value);
        const isNaN = Number.isNaN(parseFloat(paramValue));
        if (isNaN) paramValue = value;
        newValue = paramValue;
      }
      updateParam(newValue, param);
    };

    const onPlayerParamIncrease = (param) => {
      const updatedParam = cloneDeep(param);
      const updatedValue = parseFloat((updatedParam.value + updatedParam.raiseIncrement).toFixed(3));
      if (updatedValue <= updatedParam.maxValue) {
        updateParam(updatedValue, updatedParam);
      }
    };

    const onPlayerParamDecrease = (param) => {
      const updatedParam = cloneDeep(param);
      const updatedValue = parseFloat((updatedParam.value - updatedParam.raiseIncrement).toFixed(3));
      if (updatedValue >= updatedParam.minValue) {
        updateParam(updatedValue, updatedParam);
      }
    };

    return {
      isPlayerPositionError,
      isPlayerMapped,
      isSuspendActive,
      lineupParam,
      showPlayerMarkets,
      totalLineup,
      confirmModalActive,
      onPositionIncrease,
      onPositionDecrease,
      onPositionChange,
      onIsDisabledChanged,
      updatePlayerLineup,
      onLineupChange,
      onModalClose,
      onPlayerParamChange,
      onPlayerParamIncrease,
      onPlayerParamDecrease,
      formatPlayerName,
      showMinutesRemainingInfo,
      playerMinutesRemainingValue,
    };
  },
};
</script>

<style lang="scss">
.player-params-single {
  .player-params-single-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: $gray300;
    gap: 8px;
    .player-info {
      display: flex;
      align-items: center;
      gap: 8px;
      .position-input-root {
        display: flex;
        align-items: center;
        gap: 2px;
        .position-input {
          input {
            width: 34px;
            text-align: center;
            &.is-error {
              width: 54px;
            }
          }
        }
        .arrows-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .icon {
            cursor: pointer;
            svg path {
              stroke: $black;
            }
          }
        }
      }
      .player-name {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        .player-lineup-indicator {
          display: flex;
          padding: 2px;

          .icon svg path {
            stroke: $gray800;
          }

          &.is-in-lineup {
            background-color: $brandPrimary500;
            border-radius: 4px;
            .icon svg path {
              stroke: $white;
            }
          }
        }

        .tooltip {
          .tooltip-element {
            display: flex;
            align-items: center;
            }
            .tooltip-text {
              font-weight: 400;
            }

            .icon {
              &.projections-mapping-icon {
                width: 16px;
                height: 16px;

                svg {
                  path {
                    fill: #ff2e2d;
                  }
                }

                &.is-mapped {
                  svg {
                    path {
                      fill: #191414;;
                    }
                  }
                }
              }
            }
        }

        span {
          color: #6D6D6D;
          font-weight: 400
        }

        .dot-space {
          margin: 0 6px 0 4px;
        }
      }
    }
    .player-setup-header-actions {
      display: flex;
      align-items: center;
      gap: 8px;
      .player-disabled-toggle {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
  .minutes-remaining-info-root {
    padding: 8px 16px;
  }
  .player-params-single-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    padding: 12px 16px;

    &.suspended {
      background-color: $error50;
    }
    &.disabled {
      background-color: $gray500;
    }
  }
  .markets-per-player-container {
    .markets-per-player-list {
      padding: 16px;
    }
  }
}
</style>
